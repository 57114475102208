




import Vue from "vue";
import StaffelComponent from "../components/StaffelComponent.vue";

export default Vue.extend({
  name: "Staffel",

  components: {
    StaffelComponent,
  },
});
