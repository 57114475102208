






















































import Vue from "vue";
import content from "@/content.json";

export default Vue.extend({
  name: "StaffelComponent",
  data: () => ({
    page: content.pages.staffel,
    error: "",
    success: "",
    state: {
      ready: false,
      accepted: false,
    },
    loading: {
      download: false,
    },
  }),
  methods: {
    downloadFile() {
      this.loading["download"] = true;
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${this.$store.state.user.token}`,
      };
      this.$request({
        url: `${this.$store.state.APIurl}/return_staffel/download-staffel`,
        method: "POST",
        responseType: "blob",
        headers: headers,
      })
        .then((response: any) => {
          let fileURL = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "staffel.zip");
          document.body.appendChild(fileLink);
          fileLink.click();
          this.loading["download"] = false;
        })
        .catch((error: any) => {
          console.log(error);
          this.loading["download"] = false;
          this.success = "";
          this.error =
            "Het bestand kan niet worden geproduceerd. Neem contact op met de beheerder";
        });
    },
    isReady() {
      this.$request(`${this.$store.state.APIurl}/return_staffel/staffel-status`)
        .then((response: any) => {
          this.state["ready"] = response.data.isReady;
          this.state["accepted"] = response.data.accepted;
        })
        .catch((error: any) => {
          console.log(error.response);
        });
    },
    accept() {
      this.$request
        .post(`${this.$store.state.APIurl}/return_staffel/accept`)
        .then(() => {
          this.error = "";
          this.state["accepted"] = true;
          this.success = this.page.l_dropzone.success;
        })
        .catch((error: any) => {
          this.success = "";
          this.error = error.response.data.detail;
        });
    },
  },
  created() {
    this.isReady();
  },
});
